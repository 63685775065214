var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-auto" }, [
    _c("div", { staticClass: "mt-8" }, [
      _c("h2", [_vm._v("grid sample")]),
      _c(
        "div",
        { ref: "content", staticClass: "grid grid-cols-11 gap-x-8 relative" },
        [
          _vm._m(0),
          _c("div", { staticClass: "col-span-1 z-10 relative" }),
          _c(
            "div",
            { staticClass: "col-span-5 m-2 rounded-lg border z-10 relative" },
            [
              _c("FlCheckbox", [_c("span", [_vm._v("h264")])]),
              _c("FlCheckbox", [_c("span", [_vm._v("Apple ProRes422")])]),
            ],
            1
          ),
          _vm._m(1),
          _c("div", { staticClass: "col-span-1" }),
          _c(
            "div",
            { staticClass: "col-span-5 m-2 rounded-lg border z-10 relative" },
            [
              _c("FlCheckbox", [_c("span", [_vm._v("h264")])]),
              _c("FlCheckbox", [_c("span", [_vm._v("Apple ProRes422")])]),
            ],
            1
          ),
          _vm._m(2),
        ]
      ),
      _vm._v(
        ' :style="`height: calc(${$refs.content.scrollHeight}px + 1rem)`" '
      ),
    ]),
    _c(
      "div",
      { staticClass: "mt-8" },
      [
        _c("h2", [_vm._v("eval sample")]),
        _c(
          "TermsItem",
          { attrs: { label: "Main Video Format" } },
          [
            _c("TermsFieldNoEval", [
              _c("span", [
                _vm._v(
                  "here is some writing that only exists on the left side of the pane."
                ),
              ]),
              _c("span", [_vm._v("This text will not be highlighted.")]),
            ]),
            _c("TermsField", {
              attrs: {
                original: [1, 2, 3],
                eval: function (orig, tgt) {
                  orig.includes(tgt)
                },
                copy_rule: function (orig, tgt) {
                  tgt = orig[0]
                },
                reset: null,
              },
              scopedSlots: _vm._u([
                {
                  key: "original",
                  fn: function () {
                    return [
                      _c("span", [
                        _vm._v(
                          "user wants one of " + _vm._s([1, 2, 3].join(","))
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "negotiate",
                  fn: function (ref) {
                    var response = ref.response
                    return [
                      _c(
                        "FlCheckbox",
                        {
                          attrs: { value: response, trueValue: 1 },
                          on: {
                            input: function ($event) {
                              response = $event
                            },
                          },
                        },
                        [_vm._v("1")]
                      ),
                      _c(
                        "FlCheckbox",
                        {
                          attrs: { value: response, trueValue: 1 },
                          on: {
                            input: function ($event) {
                              response = $event
                            },
                          },
                        },
                        [_vm._v("2")]
                      ),
                      _c(
                        "FlCheckbox",
                        {
                          attrs: { value: response, trueValue: 1 },
                          on: {
                            input: function ($event) {
                              response = $event
                            },
                          },
                        },
                        [_vm._v("3")]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.included,
                callback: function ($$v) {
                  _vm.included = $$v
                },
                expression: "included",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-8" },
      [
        _c("FlAreaCountry", {
          model: {
            value: _vm.areaCountry,
            callback: function ($$v) {
              _vm.areaCountry = $$v
            },
            expression: "areaCountry",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-8" },
      [
        _c(
          "TermsAccordion",
          { attrs: { title: "test", outline: "outline" } },
          [
            _c(
              "TermsItem",
              {
                attrs: { showCheckbox: true, showControls: true },
                model: {
                  value: _vm.canProvide,
                  callback: function ($$v) {
                    _vm.canProvide = $$v
                  },
                  expression: "canProvide",
                },
              },
              [
                _c("TermsFieldNoEval", [
                  _c("span", [_vm._v("some sample writing.")]),
                ]),
                _c("TermsField", {
                  scopedSlots: _vm._u([
                    {
                      key: "original",
                      fn: function () {
                        return [
                          _c("span", [
                            _vm._v(
                              "希望しているフォーマットは{{}}のうちの一つです。"
                            ),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "negotiate",
                      fn: function () {
                        return undefined
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-8" },
      [
        _c("ValidationObserver", {
          attrs: { mode: "eager" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (ref) {
                var valid = ref.valid
                var invalid = ref.invalid
                var changed = ref.changed
                var touched = ref.touched
                var untouched = ref.untouched
                var pristine = ref.pristine
                var dirty = ref.dirty
                var pending = ref.pending
                var validated = ref.validated
                var passed = ref.passed
                var failed = ref.failed
                var errors = ref.errors
                return [
                  _c("div", { staticClass: "w-full grid grid-cols-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: valid ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("valid")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: invalid ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("invalid")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: changed ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("changed")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: touched ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("touched")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: untouched ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("untouched")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: pristine ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("pristine")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: dirty ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("dirty")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: pending ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("pending")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: validated ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("validated")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: passed ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("passed")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: failed ? "bg-green-600" : "bg-red-600",
                      },
                      [_vm._v("failed")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "col-span-1 rounded-full p-2",
                        class: _vm.evalErrors(errors)
                          ? "bg-green-600"
                          : "bg-red-600",
                      },
                      [_vm._v("errors")]
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "grid-span-12" },
                    [
                      _c("FlInput", {
                        attrs: {
                          rules: { required: true, min: 6 },
                          immediate: true,
                        },
                        model: {
                          value: _vm.someTextVal,
                          callback: function ($$v) {
                            _vm.someTextVal = $$v
                          },
                          expression: "someTextVal",
                        },
                      }),
                      _c("FlInput", {
                        attrs: {
                          rules: { required: true, min: 3, max: 7 },
                          immediate: true,
                        },
                        model: {
                          value: _vm.someTextVal2,
                          callback: function ($$v) {
                            _vm.someTextVal2 = $$v
                          },
                          expression: "someTextVal2",
                        },
                      }),
                      _c(
                        "ValidationProvider",
                        {
                          attrs: {
                            rules: { required: { allowFalse: false } },
                            immediate: true,
                          },
                        },
                        [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.canProvide,
                                  expression: "canProvide",
                                },
                              ],
                              staticClass:
                                "rounded-sm w-5 h-5 disabled:bg-brown-grey text-white inline-block relative align-middle",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.canProvide)
                                  ? _vm._i(_vm.canProvide, null) > -1
                                  : _vm.canProvide,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.canProvide,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.canProvide = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.canProvide = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.canProvide = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" sample "),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-5 m-2 rounded-lg border z-10 relative" },
      [
        _c("p", [_vm._v("customer wants format in h264 or Apple ProRes422")]),
        _c("p", [_vm._v("customer wants format in h264 or Apple ProRes422")]),
        _c("p", [_vm._v("customer wants format in h264 or Apple ProRes422")]),
        _c("p", [_vm._v("customer wants format in h264 or Apple ProRes422")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-span-5 m-2 rounded-lg border z-10 relative" },
      [
        _c("p", [_vm._v("customer wants format in h264 or Apple ProRes422")]),
        _c("p", [_vm._v("customer wants format in h264 or Apple ProRes422")]),
        _c("p", [_vm._v("customer wants format in h264 or Apple ProRes422")]),
        _c("p", [_vm._v("customer wants format in h264 or Apple ProRes422")]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "grid grid-cols-11 gap-x-6 absolute inset-0 border-2" },
      [
        _c("div", {
          staticClass: "col-span-5 bg-red-100 rounded z-0 inset-y-0",
        }),
        _c("div", { staticClass: "col-span-1 z-0" }),
        _c("div", {
          staticClass: "col-span-5 bg-blue-100 rounded z-0 inset-y-0",
        }),
        _c("div", {
          staticClass: "absolute inset-2 opacity-25 hover:bg-green-500 rounded",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }