var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("TermsField", {
    scopedSlots: _vm._u(
      [
        {
          key: "original",
          fn: function () {
            return [_vm._t("default")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }